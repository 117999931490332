import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {InputCurrency, TableCell, TableRow, useStyles} from '@ace-de/ui-components';
import {useTranslate} from '@computerrock/formation-i18n';
import generateTariffDetails from '../generateTariffDetails';

const RatesByTimeRow = props => {
    const {cx} = useStyles();
    const {translate} = useTranslate();
    const {timePrice, prices, setPrices} = props;
    const tariffDetails = generateTariffDetails(timePrice.tariff);
    const [price, setPrice] = useState(timePrice.value >= 0 ? timePrice.value : '');

    const handleOnChange = (value, tariff) => {
        setPrice(value);

        const updatedPrices = [...prices].map(price => {
            const newPrice = {...price};
            if (price.tariff === tariff) {
                newPrice.value = value;
            }
            return newPrice;
        });

        setPrices([...updatedPrices]);
    };

    return (
        <TableRow qaIdentPart={timePrice.id}>
            <TableCell colSpan={3} qaIdentPart="edit-rates-by-time-day">
                {translate(`global.day.${tariffDetails?.days[0].toLowerCase()}`) || '-'}
            </TableCell>
            <TableCell colSpan={2} qaIdentPart="edit-rates-by-time-from">
                {tariffDetails?.timeSlot[0] || '-'}
            </TableCell>
            <TableCell colSpan={2} qaIdentPart="edit-rates-by-time-to">
                {tariffDetails?.timeSlot[1] || '-'}
            </TableCell>
            <TableCell colSpan={3} qaIdentPart="edit-rates-by-time-price">
                <InputCurrency
                    name="inflationSubsidy"
                    className={cx('ace-c-input-currency--small')}
                    value={price || ''}
                    onChange={value => handleOnChange(value, timePrice.tariff)}
                />
            </TableCell>
        </TableRow>
    );
};

RatesByTimeRow.propTypes = {
    timePrice: PropTypes.object.isRequired,
    prices: PropTypes.array.isRequired,
    setPrices: PropTypes.func.isRequired,
};

export default RatesByTimeRow;
