import React, {useState, useEffect, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {InputCurrency, NumberInput, TableCell, TableRow, useStyles} from '@ace-de/ui-components';
import config from '../../config';

const PickupRatesRow = props => {
    const {cx} = useStyles();
    const focusedField = useRef();
    const {setPickupRates, pickupRates, pickupPrice, overlappingFields, setIsButtonDisabled} = props;
    const pricePeriodId = pickupPrice?.id;
    const isOverlapping = overlappingFields && overlappingFields.includes(pricePeriodId);

    const [pickupPriceData, setPickupPriceData] = useState({
        value: pickupPrice?.value || '',
        distanceFrom: pickupPrice?.distanceFrom || '',
        distanceTo: pickupPrice?.distanceTo || '',
    });
    const [hasDistanceError, setHasDistanceError] = useState({
        distanceFrom: false,
        distanceTo: false,
    });

    const validateFields = useCallback(() => {
        if (!pickupPriceData) return;

        const {distanceFrom, distanceTo} = pickupPriceData;

        setHasDistanceError({
            distanceFrom: (distanceFrom < config.MINIMUM_DISTANCE_VALUE || distanceFrom > config.MAXIMUM_DISTANCE_VALUE)
                || (focusedField.current === 'distanceFrom' && distanceFrom > distanceTo),
            distanceTo: (distanceTo < config.MINIMUM_DISTANCE_VALUE || distanceTo > config.MAXIMUM_DISTANCE_VALUE)
                || (focusedField.current === 'distanceTo' && distanceTo < distanceFrom),
        });
    }, [setHasDistanceError, pickupPriceData]);

    useEffect(() => {
        setIsButtonDisabled(!!hasDistanceError.distanceFrom || !!hasDistanceError.distanceTo);
    }, [hasDistanceError, setIsButtonDisabled]);

    useEffect(() => {
        validateFields();
    }, [validateFields]);

    const handleOnChange = (value, fieldName) => {
        setPickupPriceData({
            ...pickupPriceData,
            [fieldName]: value,
        });

        focusedField.current = fieldName;

        const updatedPrices = [...pickupRates].map(pickupRate => {
            const newPickupRate = {...pickupRate};
            if (pickupRate.id && pickupRate.id === pricePeriodId) {
                newPickupRate[fieldName] = fieldName === 'value'
                    ? parseFloat(value)
                    : parseInt(value, 10);
            }
            return newPickupRate;
        });

        setPickupRates([...updatedPrices]);
    };

    return (
        <TableRow>
            <TableCell colSpan={3}>
                <div className={cx('global!ace-u-grid')}>
                    <NumberInput
                        name="distanceFrom"
                        className={cx([
                            'ace-c-number-input--small',
                            'global!ace-u-grid-column--span-9',
                        ])}
                        value={pickupPriceData?.distanceFrom || '-'}
                        onChange={value => handleOnChange(value, 'distanceFrom')}
                        errors={(isOverlapping || hasDistanceError['distanceFrom']) ? ['error'] : []}
                    />
                </div>
            </TableCell>
            <TableCell colSpan={3}>
                <div className={cx('global!ace-u-grid')}>
                    <NumberInput
                        name="distanceTo"
                        className={cx([
                            'ace-c-number-input--small',
                            'global!ace-u-grid-column--span-9',
                        ])}
                        value={pickupPriceData?.distanceTo || '-'}
                        onChange={value => handleOnChange(value, 'distanceTo')}
                        errors={(isOverlapping || hasDistanceError['distanceTo']) ? ['error'] : []}
                    />
                </div>
            </TableCell>
            <TableCell colSpan={4}>
                <InputCurrency
                    name="value"
                    className={cx([
                        'ace-c-input-currency--small',
                        'global!ace-u-grid-column--span-12',
                    ])}
                    value={pickupPriceData?.value || '-'}
                    onChange={value => handleOnChange(value, 'value')}
                />
            </TableCell>
        </TableRow>
    );
};

PickupRatesRow.propTypes = {
    pickupPrice: PropTypes.object.isRequired,
    pickupRates: PropTypes.array.isRequired,
    setPickupRates: PropTypes.func.isRequired,
    setIsButtonDisabled: PropTypes.func.isRequired,
    overlappingFields: PropTypes.array,
};

PickupRatesRow.defaultProps = {
    overlappingFields: null,
};

export default PickupRatesRow;
