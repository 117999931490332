import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ButtonPrimary, useStyles, InputCurrency, warningIcon, Icon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {apsPriceTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import config from '../../config';

const EditSimpleServiceRatesData = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const {pricePeriodId} = useSearchQueryParams();
    const translateModal = createTranslateShorthand('edit_simple_service_rates_modal');
    const {confirmUpdateAdditionalRates, recoveryPrices} = props;
    const [price, setPrice] = useState(recoveryPrices?.[0].value || '');
    const [errorMessage, setErrorMessage] = useState('');

    const handleOnClick = () => {
        confirmUpdateAdditionalRates({
            pricePeriodId: parseInt(pricePeriodId, 10),
            prices: [{
                value: parseFloat(price),
                type: apsPriceTypes.RECOVERY_PRICE,
            }],
        });
    };

    const validateFields = useCallback(() => {
        if (!price) {
            setErrorMessage(translateModal('error_message.all_fields_must_be_populated'));
            return;
        }

        if (price === `${config.NOT_ALLOWED_PRICE_VALUE}`) {
            setErrorMessage(translate('global.error_message.zero_values_not_allowed'));
            return;
        }

        setErrorMessage('');
    }, [setErrorMessage, price, translate, translateModal]);

    useEffect(() => {
        validateFields();
    }, [validateFields]);

    return (
        <div className={cx('global!ace-u-margin--32-0')}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="edit-additional-rates-service" colSpan={2}>
                            {translateModal('table_header.service')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-additional-rates-time" colSpan={2}>
                            {translateModal('table_header.unit')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-additional-rates-price" colSpan={4}>
                            {translateModal('table_header.price')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {translateModal('table_cell.recovery')}
                        </TableCell>
                        <TableCell colSpan={2}>
                            {config.ADDITIONAL_RATES_BY_TIME_UNIT}
                        </TableCell>
                        <TableCell colSpan={4}>
                            <InputCurrency
                                name="price"
                                className={cx('ace-c-input-currency--small')}
                                value={price || ''}
                                onChange={setPrice}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {!!errorMessage && (
                <p
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-margin--top-16',
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                    <span>
                        {errorMessage}
                    </span>
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-margin--top-32',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-flex-end',

                ])}
            >
                <ButtonPrimary
                    onClick={handleOnClick}
                    className={cx('global!ace-u-margin--right-32')}
                    isDisabled={!!errorMessage}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </div>
    );
};

EditSimpleServiceRatesData.propTypes = {
    confirmUpdateAdditionalRates: PropTypes.func.isRequired,
    recoveryPrices: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return ({
        recoveryPrices: state.priceManagement.recoveryPrices,
    });
};

const mapDispatchToProps = dispatch => ({
    confirmUpdateAdditionalRates: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSimpleServiceRatesData);
