import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {camelCase} from 'change-case';
import {withRouter} from '@computerrock/formation-router';
import {useStyles, Panel, ContentItem, ContentBlock, Divider, Icon, ButtonSecondary, ButtonPrimary} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {Form, Select, Option, RadioButtonGroup, RadioButton, CheckboxGroup, Checkbox} from '@ace-de/ui-components/form';
import {closeIcon, editIcon, checkmarkIcon, InteractiveIcon} from '@ace-de/ui-components/icons';
import {useTranslate} from '@computerrock/formation-i18n';
import {apmACEPartnerServiceTypes, apmContractPartnerVehicleCompatibilityTypes, apmContractPartnerServiceTonnageTypes, ampVehicleWeightTypes, apmContractPartnerServiceAreaCountryTypes} from '@ace-de/eua-entity-types';
import * as contractPartnerSelectors from '../contractPartnerSelectors';
import * as contractPartnerActionTypes from '../contractPartnerActionTypes';
import createNewService from '../createNewService';
import config from '../../config';

const ContractPartnerBasicServicesPanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate} = useTranslate();
    const translateTab = createTranslateShorthand('contract_partner_services_tab');
    const {contractPartner, submitBasicServicesForm} = props;
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [formData, setFormData] = useState({
        serviceAreaCountries: contractPartner?.serviceAreaCountries,
        roadsideAssistance: contractPartner?.roadsideAssistance,
        towing: contractPartner?.towing,
        recovery: contractPartner?.recovery,
        vehicleCompatibility: contractPartner?.vehicleCompatibility,
    });

    const toggleEditMode = () => {
        setIsEditModeActive(prevState => !prevState);
    };

    if (!contractPartner) return null;

    const handleOnChange = formValues => {
        setFormData(formValues);
    };

    const handleOnSubmit = formValues => {
        const doesServiceExist = {
            [apmACEPartnerServiceTypes.RECOVERY]: false,
            [apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE]: false,
            [apmACEPartnerServiceTypes.TOWING]: false,
        };
        // update only existing basic services, and keep all the other services
        const updatedServices = contractPartner.services?.length
            ? contractPartner.services.map(service => {
                // if it's a basic service, overwrite the tonnage
                if ([
                    apmACEPartnerServiceTypes.RECOVERY,
                    apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                    apmACEPartnerServiceTypes.TOWING,
                ].includes(service.serviceType)) {
                    doesServiceExist[service.serviceType] = true;
                    return {
                        ...service,
                        tonnageThreshold: formValues[camelCase(service.serviceType)],
                    };
                }
                return service;
            }) : [];
        // if service is not already present in services array, add it
        for (const [serviceType, value] of Object.entries(doesServiceExist)) {
            if (!value) {
                updatedServices.push({
                    ...(createNewService(serviceType)),
                    tonnageThreshold: formValues[camelCase(serviceType)],
                });
            }
        }
        let servicesData = contractPartner.services?.length
            ? updatedServices
            : [
                {
                    ...(createNewService(apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE)),
                    tonnageThreshold: formValues['roadsideAssistance'],
                },
                {
                    ...(createNewService(apmACEPartnerServiceTypes.TOWING)),
                    tonnageThreshold: formValues['towing'],
                },
                {
                    ...(createNewService(apmACEPartnerServiceTypes.RECOVERY)),
                    tonnageThreshold: formValues['recovery'],
                },
            ];

        // only when ROADSIDE_ASSISTANCE service is supported, VEHICLE_OPENING should exist
        const roadsideAssistanceTonnage = servicesData
            .find(service => service.serviceType === apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE)?.tonnageThreshold || 0; // eslint-disable-line max-len
        if (roadsideAssistanceTonnage > 0 && !servicesData
            .find(service => service.serviceType === apmACEPartnerServiceTypes.VEHICLE_OPENING)) {
            servicesData.push({
                ...(createNewService(apmACEPartnerServiceTypes.VEHICLE_OPENING)),
            });
        }

        if (!roadsideAssistanceTonnage) {
            servicesData = servicesData
                .filter(service => service.serviceType !== apmACEPartnerServiceTypes.VEHICLE_OPENING);
        }

        // remove services where tonnage is 0
        servicesData = servicesData.filter(service => {
            if ([
                apmACEPartnerServiceTypes.RECOVERY,
                apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                apmACEPartnerServiceTypes.TOWING,
            ].includes(service.serviceType)) {
                return !!service.camperDimensions || (!!service.tonnageThreshold && service.tonnageThreshold > 0);
            }
            return true;
        });

        const contractPartnerData = {
            // only when ROADSIDE_ASSISTANCE service is supported, VEHICLE_OPENING should exist
            services: servicesData,
            serviceAreaCountries: formValues['serviceAreaCountries'],
            vehicleCompatibility: formValues['vehicleCompatibility'],
        };

        submitBasicServicesForm({
            contractPartnerData,
            contractPartnerId: contractPartner.id,
        });
        setIsEditModeActive(false);
    };
    return (
        <Panel>
            <Form
                name="basicServicesForm"
                onSubmit={handleOnSubmit}
                onChange={handleOnChange}
            >
                <ContentBlock>
                    <ContentItem className={cx('global!ace-u-flex--shrink-1')}>
                        <div
                            className={cx([
                                'global!ace-u-margin--bottom-24',
                                'global!ace-u-flex',
                                'global!ace-u-flex--justify-space-between',
                                'global!ace-u-flex--align-stretch',
                            ])}
                        >
                            <h3 className={cx('global!ace-u-typography--variant-h3')}>
                                {translateTab('content_item_header.service_area')}
                            </h3>
                            <InteractiveIcon
                                icon={!isEditModeActive ? editIcon : closeIcon}
                                onClick={toggleEditMode}
                            />
                        </div>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={3}
                                        className={cx('ace-c-table__cell--highlighted')}
                                    >
                                        {translateTab('table_header.foreign_services')}
                                    </TableCell>
                                    <TableCell
                                        colSpan={9}
                                    >
                                        <div
                                            className={cx(['global!ace-u-inline-flex', 'global!ace-u-full-width'])}
                                        >
                                            {!isEditModeActive && (
                                                <Icon
                                                    icon={contractPartner?.serviceAreaCountries.length > 0
                                                        ? checkmarkIcon : closeIcon}
                                                    className={cx([
                                                        'global!ace-u-margin--right-16',
                                                        contractPartner?.serviceAreaCountries.length > 0
                                                            ? 'ace-c-icon--color-success'
                                                            : 'ace-c-icon--color-disabled',
                                                    ])}
                                                />
                                            )}
                                            {!isEditModeActive
                                                ? (contractPartner.serviceAreaCountries.length > 0
                                                        && contractPartner.serviceAreaCountries.map(country => (
                                                            translate(`global.country.${country.toLowerCase()}`)
                                                        )).join(', '))
                                                : (
                                                    <Select
                                                        name="serviceAreaCountries"
                                                        value={contractPartner?.serviceAreaCountries || ''}
                                                        className={cx([
                                                            'global!ace-u-full-width',
                                                        ])}
                                                        isMultipleChoice={true}
                                                    >
                                                        {Object.values(apmContractPartnerServiceAreaCountryTypes)
                                                            .map(country => (
                                                                <Option
                                                                    key={country}
                                                                    value={country}
                                                                    name={`option-${country.toLowerCase()}`}
                                                                    isSelected={true}
                                                                >
                                                                    {translate(`global.country.${country.toLowerCase()}`)}
                                                                </Option>
                                                            ))}
                                                    </Select>
                                                )
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </ContentItem>
                </ContentBlock>
                <ContentBlock>
                    <ContentItem className={cx('global!ace-u-flex--shrink-1')}>
                        <h3
                            className={cx([
                                'global!ace-u-typography--variant-h3',
                                'global!ace-u-margin--bottom-24',
                            ])}
                        >
                            {translateTab('content_item_header.basic_services')}
                        </h3>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3} />
                                    {Object.values(apmContractPartnerServiceTonnageTypes).map(tonnage => (
                                        <TableCell key={tonnage}>
                                            {tonnage !== apmContractPartnerServiceTonnageTypes.NO_SERVICES
                                                ? (tonnage !== apmContractPartnerServiceTonnageTypes.ULTRA_HEAVY_WEIGHT_VEHICLE // eslint-disable-line max-len
                                                    ? `${translateTab('table_header.to_vehicle_weight_limit', {
                                                        vehicleWeightLimit: new Intl.NumberFormat('de-DE', {
                                                            style: 'unit',
                                                            unit: 'kilogram',
                                                        }).format(ampVehicleWeightTypes[tonnage]),
                                                    })}`
                                                    : `${translateTab('table_header.over_vehicle_weight_limit', {
                                                        vehicleWeightLimit: new Intl.NumberFormat('de-DE', {
                                                            style: 'unit',
                                                            unit: 'kilogram',
                                                        }).format(ampVehicleWeightTypes.HEAVY_WEIGHT_VEHICLE),
                                                    })}`
                                                ) : translateTab('table_header.no_services')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[
                                    apmACEPartnerServiceTypes.RECOVERY,
                                    apmACEPartnerServiceTypes.ROADSIDE_ASSISTANCE,
                                    apmACEPartnerServiceTypes.TOWING,
                                ].map(service => {
                                    const cpTThreshold = contractPartner[camelCase(service)]?.tonnageThreshold;
                                    return (
                                        <TableRow key={service}>
                                            <TableCell
                                                colSpan={3}
                                            >
                                                {translateTab(`table_row_label.${service.toLowerCase()}`)}
                                            </TableCell>
                                            <RadioButtonGroup
                                                name={camelCase(service)}
                                                value={isEditModeActive
                                                    ? typeof formData[camelCase(service)] === 'object'
                                                        ? formData[camelCase(service)]?.tonnageThreshold || 0
                                                        : formData[camelCase(service)] || 0
                                                    : cpTThreshold}
                                            >
                                                {Object.values(apmContractPartnerServiceTonnageTypes)
                                                    .map((tonnage, idx) => {
                                                        /* eslint-disable max-len */
                                                        const cpTonnage = tonnage !== apmContractPartnerServiceTonnageTypes.NO_SERVICES
                                                            ? (tonnage !== apmContractPartnerServiceTonnageTypes.ULTRA_HEAVY_WEIGHT_VEHICLE
                                                                ? ampVehicleWeightTypes[tonnage]
                                                                : config.MIN_ULTRA_HEAVY_WEIGHT_VEHICLE_TONNAGE
                                                            ) : 0;
                                                        /* eslint-enable max-len */
                                                        return (
                                                            <TableCell key={tonnage}>
                                                                {!isEditModeActive
                                                                    ? (
                                                                        <Icon
                                                                            icon={cpTonnage !== 0
                                                                                ? cpTThreshold >= cpTonnage
                                                                                    ? checkmarkIcon : closeIcon
                                                                                : (cpTThreshold > 0
                                                                                    ? closeIcon : checkmarkIcon)}
                                                                            className={cx([
                                                                                'global!ace-u-margin--right-16',
                                                                                cpTonnage !== 0
                                                                                    ? cpTThreshold >= cpTonnage
                                                                                        ? 'ace-c-icon--color-success' : 'ace-c-icon--color-disabled'
                                                                                    : (cpTThreshold > 0
                                                                                        ? 'ace-c-icon--color-disabled'
                                                                                        : 'ace-c-icon--color-success'),
                                                                            ])}
                                                                        />
                                                                    ) : (
                                                                        <RadioButton
                                                                            value={cpTonnage}
                                                                            name={`${service}_${tonnage}`}
                                                                        />
                                                                    )}
                                                            </TableCell>
                                                        );
                                                    })}
                                            </RadioButtonGroup>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <Divider />
                    </ContentItem>
                </ContentBlock>
                <ContentBlock>
                    <ContentItem className={cx('global!ace-u-flex--shrink-1')}>
                        <h3
                            className={cx([
                                'global!ace-u-typography--variant-h3',
                                'global!ace-u-margin--bottom-24',
                            ])}
                        >
                            {translateTab('content_item_header.vehicle_compatibility')}
                        </h3>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={3} />
                                    <CheckboxGroup
                                        name="vehicleCompatibility"
                                        value={isEditModeActive
                                            ? formData?.vehicleCompatibility
                                            : contractPartner?.vehicleCompatibility}
                                    >
                                        {Object.values(apmContractPartnerVehicleCompatibilityTypes).map(vehicleType => (
                                            <TableCell colSpan={2} key={vehicleType}>
                                                <div className={cx('global!ace-u-inline-flex')}>
                                                    {!isEditModeActive
                                                        ? (
                                                            <Icon
                                                                icon={contractPartner.vehicleCompatibility.includes(vehicleType) // eslint-disable-line max-len
                                                                    ? checkmarkIcon : closeIcon}
                                                                className={cx([
                                                                    'global!ace-u-margin--right-16',
                                                                    contractPartner.vehicleCompatibility.includes(vehicleType) // eslint-disable-line max-len
                                                                        ? 'ace-c-icon--color-success'
                                                                        : 'ace-c-icon--color-disabled',
                                                                ])}
                                                            />
                                                        ) : (
                                                            <Checkbox value={vehicleType} name={vehicleType} />
                                                        )}
                                                    {translateTab(`table_row_label.${vehicleType.toLowerCase()}`)}
                                                </div>
                                            </TableCell>
                                        ))}
                                    </CheckboxGroup>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Divider />
                    </ContentItem>
                </ContentBlock>
                {isEditModeActive && (
                    <div
                        className={cx('global!ace-u-flex', 'global!ace-u-flex--justify-flex-end')}
                    >
                        <ButtonSecondary
                            onClick={() => setIsEditModeActive(false)}
                        >
                            {translateTab('button_label.discard')}
                        </ButtonSecondary>
                        <ButtonPrimary
                            className={cx('global!ace-u-margin--left-24')}
                            name="submitForm"
                            type="submit"
                        >
                            {translateTab('button_label.save')}
                        </ButtonPrimary>
                    </div>
                )}
            </Form>
        </Panel>
    );
};

ContractPartnerBasicServicesPanel.propTypes = {
    contractPartner: PropTypes.object,
    submitBasicServicesForm: PropTypes.func.isRequired,
};

ContractPartnerBasicServicesPanel.defaultProps = {
    contractPartner: null,
};

const mapStateToProps = (state, props) => {
    const contractPartnerSelector = contractPartnerSelectors.createContractPartnerSelector();

    return {
        contractPartner: contractPartnerSelector(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    submitBasicServicesForm: payload => dispatch({
        type: contractPartnerActionTypes.INITIATE_CP_BASIC_SERVICES_UPDATE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPartnerBasicServicesPanel));
